import {
  MouseEvent,
  MutableRefObject,
  ReactNode,
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
} from 'react'
import { DialogContent, DialogOverlay, DialogOverlayProps } from '@reach/dialog'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import clsx from 'clsx'
import {
  modalOverlay,
  modalContent,
  modalContentDefaultStyle,
  modalTransitionDuration,
  modalTransformOrigin,
} from './Modal.css'

interface TriggerOrigin {
  left: number
  top: number
}

export interface ModalProps
  extends Omit<DialogOverlayProps, 'onDismiss' | 'className'> {
  children: ReactNode
  closeModal: DialogOverlayProps['onDismiss']
  className?: string
  overlayClassName?: string
  closeOnOutsideClick?: boolean // Use when user is required an action
  closeOnEscape?: boolean // Use when displaying information that cannot be trivially re-required. Still allows Escape
  origin?: TriggerOrigin
  onClosedCallback?: () => void
  transitionDuration?: number // Could just be in CSS now, but perhaps we wanna use this at some point depending on modal content
}

interface InnerModalProps {
  children: ReactNode
  className?: string
  origin?: TriggerOrigin
  onMount?: () => void
  onUnMount?: () => void
}

const ModalInner = forwardRef<HTMLDivElement, InnerModalProps>(
  function ModalInner(
    { children, className, origin, onMount, onUnMount },
    ref,
  ) {
    const typedRef = ref as MutableRefObject<HTMLDivElement>
    const onUnMountRef = useRef(onUnMount)

    useEffect(() => {
      onMount?.()
      return () => {
        onUnMountRef.current?.()
      }
    }, [onMount])

    useEffect(() => {
      // Store unmount callback to a ref so its update (after mount) wont trigger unmount/remount
      onUnMountRef.current = onUnMount
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onUnMountRef])

    return (
      <DialogContent
        ref={ref}
        // Use provided style or default style. Allows for less specific styling
        className={clsx(modalContent, className ?? modalContentDefaultStyle)}
        // Assign transform origin based on origin of trigger (if given) and modal content's size
        style={assignInlineVars({
          [modalTransformOrigin]: origin
            ? `${origin.left + (typedRef.current?.clientWidth ?? 0) / 2}px ${
                origin.top + (typedRef.current?.clientHeight ?? 0) / 2
              }px`
            : '',
        })}
      >
        {children}
      </DialogContent>
    )
  },
)

export const Modal = ({
  closeModal,
  onClosedCallback,
  children,
  isOpen = false, // Make sure this stays boolean and not undefined
  className,
  overlayClassName,
  closeOnEscape = true,
  closeOnOutsideClick = true,
  origin, // Origin of the modal/trigger of it
  transitionDuration = 300,
  ...dialogProps
}: ModalProps) => {
  const dialogRef = useRef(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const isMountedOpenRef = useRef(isOpen)
  const [isModalVisible, setIsModalVisbile] = useState(isOpen)
  const [isInTransition, setIsInTransition] = useState(false)

  const handleCSSTransition = useCallback(
    (ref: MutableRefObject<HTMLDivElement | null>) => {
      if (ref.current) {
        ref.current.addEventListener(
          'transitionend',
          function onTransitionEnd() {
            ref?.current?.removeEventListener('transitionend', onTransitionEnd)
            setIsInTransition(false)
          },
        )

        setIsInTransition(true)
      }
    },
    [],
  )

  const startTransitionHandler = useCallback(() => {
    /*
     * Dont try to handle nice transitions if modal mounts as open. The modal will
     * just leave hanging in "in-progress" because the transition completes before JS runs most of the time
     * -> Causes any focus/click event on the modal to just close it
     */
    if (!isMountedOpenRef.current) {
      handleCSSTransition(contentRef)
      setIsModalVisbile(true)
    }
  }, [handleCSSTransition])

  useEffect(() => {
    // This closes the modal. Opening transition is triggered by Inner modal
    if (!isOpen) {
      setIsModalVisbile(false)
      handleCSSTransition(contentRef)
    }
  }, [isOpen, handleCSSTransition])

  const isInDom =
    isOpen || isModalVisible || (!isModalVisible && isInTransition)

  const onDismiss: NonNullable<DialogOverlayProps['onDismiss']> = useCallback(
    (e) => {
      if (closeOnOutsideClick) {
        closeModal?.(e)
      } else {
        // If event is keyboard event instead of a mouse event
        if (e.type === 'keydown') {
          closeModal?.(e)
        } else {
          /*
           * Lazy way of keeping focus on modal. Not ideal, but nice to have in any case.
           * This allows misclick on outside of the modal, spending some time reading and still after that closing with Escape.
           * Without this, escape wont work before focus is restored on modal.
           */
          contentRef.current?.focus?.()
        }
      }
    },
    [closeOnOutsideClick, closeModal],
  )

  return (
    <DialogOverlay
      className={clsx(modalOverlay, overlayClassName)}
      onDismiss={closeOnEscape ? onDismiss : undefined}
      isOpen={isInDom}
      data-transition-state={isInTransition ? 'in-progress' : ''}
      data-visible-state={isModalVisible ? 'visible' : 'hidden'}
      style={assignInlineVars({
        [modalTransitionDuration]: `${transitionDuration}ms`,
      })}
      ref={dialogRef}
      {...dialogProps}
    >
      <ModalInner
        origin={origin}
        onMount={startTransitionHandler}
        onUnMount={onClosedCallback}
        className={className}
        ref={contentRef}
      >
        {children}
      </ModalInner>
    </DialogOverlay>
  )
}

interface UseModalProps
  extends Pick<ModalProps, 'closeOnOutsideClick' | 'closeOnEscape'> {
  isDefaultOpen?: boolean
}

interface ModalTriggerState {
  isOpen: boolean
  origin?: TriggerOrigin
}

export type OpenModal = (
  e?: MouseEvent<HTMLElement> | MouseEvent<HTMLElement>['currentTarget'],
) => void

export type CloseModal = () => Promise<void>

interface UseModalReturnProps extends Pick<ModalProps, 'isOpen'> {
  // Open supports element or event. We wanna use currentTarget, so it truly is the trigger, but currentTarget wont work async, so allow passing stored currentTarget
  openModal: OpenModal
  closeModal: CloseModal
  modalProps: ModalTriggerState &
    Pick<ModalProps, 'closeOnEscape' | 'closeOnOutsideClick'> & {
      closeModal: () => void
      onClosedCallback?: () => void
    }
}

const isEvent = (
  x: MouseEvent<HTMLElement> | MouseEvent<HTMLElement>['currentTarget'],
): x is MouseEvent<HTMLElement> =>
  typeof (x as MouseEvent<HTMLElement>)?.target !== 'undefined'

export const useModal = ({
  isDefaultOpen = false,
  closeOnEscape = true,
  closeOnOutsideClick = true,
}: UseModalProps = {}): UseModalReturnProps => {
  const [{ isOpen, origin }, setOpen] = useState<ModalTriggerState>({
    isOpen: isDefaultOpen,
  })

  // Ref to store resolve callback
  const onClosedRef = useRef<() => void>()
  // Pending promise whenever modal is open
  const pendingOnClosedRef = useRef<Promise<void>>()

  const closeModal: CloseModal = useCallback(async () => {
    // NOTE: issue with first awaiting for some reason. not realiable
    setOpen((s) => ({ ...s, isOpen: false }))
    return pendingOnClosedRef.current ?? Promise.resolve()
  }, [])

  const openModal: OpenModal = useCallback((eventOrTarget) => {
    try {
      if (eventOrTarget) {
        /*
         * Modal supports animating the modal from the origin of the trigger. Calculate origin here in relation to
         * center of the screen (CSS transform-origin works like that).
         */
        const { left, top, width, height } = (
          isEvent(eventOrTarget) ? eventOrTarget.currentTarget : eventOrTarget
        ).getBoundingClientRect()
        startTransition(() => {
          setOpen((s) => ({
            ...s,
            isOpen: true,
            origin: {
              left:
                window.innerWidth / 2 - (window.innerWidth - left) + width / 2,
              top:
                window.innerHeight / 2 -
                (window.innerHeight - top) +
                height / 2,
            },
          }))
        })
      } else {
        throw 'open without transition origin'
      }
    } catch (error) {
      if (typeof error !== 'string') {
        console.error('Modal opening error', error)
      }
      startTransition(() => {
        setOpen((s) => ({ ...s, isOpen: true }))
      })
    }

    pendingOnClosedRef.current = new Promise((resolve) => {
      onClosedRef.current = resolve
    })
  }, [])

  return {
    isOpen,
    openModal,
    closeModal,
    modalProps: {
      isOpen,
      closeOnEscape,
      closeOnOutsideClick,
      closeModal,
      origin,
      onClosedCallback: onClosedRef.current,
    },
  }
}
