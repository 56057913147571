import { SVGProps } from 'react'

export default function External(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.667.333L.236 7.764M.663.334h7.004V7.34"
        stroke="currentColor"
      />
    </svg>
  )
}
