import React, { SVGProps } from 'react'

export default function User(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.499 11.478c0-2.588-2.012-4.695-4.505-4.695-2.492 0-4.494 2.097-4.494 4.695h8.999ZM5.994 4.696c1.106 0 2.003-.935 2.003-2.087C7.997 1.456 7.1.522 5.994.522c-1.105 0-2.002.934-2.002 2.087 0 1.152.897 2.087 2.002 2.087Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
