import retry from "async-retry-ng"
import type {
  ProjectMetricsType,
  AuthType,
  UserDataType,
  ProjectsMetricAggregations,
  ProjectsType,
  ProjectType,
  SqlApiParams,
  TrendingReceiverAccount,
  VestingSchedule,
  MetricsMomentum,
  MarketSector,
  MarketSectorEntry,
  ProjectMetricChartData,
  MarketSectorAggregated,
  MetricWithAggregates,
  CustomChartType,
  ProjectsMetadataType,
  MartPreset,
  MartPresetMeta,
  Mart,
} from "types/ApiTypes"
import { APPLICATION, APP_VERSION, TT_DEFAULT_JWT } from "constants/app.mjs"
import { POSSIBLE_METRICS_ENUM } from "constants/metrics"
import { FinancialStatementDataType } from "modules/FinancialStatement/FinancialStatement.utils"
import { getConfiguration } from "modules/configuration"
import { ChartData, TimestampedChartData } from "types/api/ChartTypes"
import { getCookie } from "utils/cookies"
import { ZOOM_ENUM, type ZoomValues } from "modules/chart/Zoom/constants"
import { COOKIE_IDTOKEN, SESSION_EXPIRED_TYPE } from "context/AuthContext"
import { SessionExpiredError } from "utils/errors"
import type { IServerSideGetRowsRequest } from "@tokenterminal/ui/DataTable"
import { UserType } from "types/Types"
// TODO scale this ability to other projects
import {
  metricsConfiguration as ICMetricConfiguration,
  methodologies as ICMethodologies,
} from "data/projects/internetcomputer/metrics.mjs"

export function getApiToken() {
  if (!!getCookie(COOKIE_IDTOKEN)) {
    return null
  }

  const config = getConfiguration()
  return config.API_DEFAULT_TOKEN() ?? null
}

export function addUrlPrefix(
  url: string,
  internal: boolean = false,
  version: string = "v2"
) {
  // starts with v1 or v2 or with has /internal as first or second part
  if (/^\/v\d\//.test(url) || /^(\/[^\/]+)?\/internal\//.test(url)) {
    throw new Error(
      `${url} should not contain a version or internal prefix, please use the proper arguments`
    )
  }
  if (APPLICATION === "bloomberg") {
    // TODO TEMP fix for bloomberg SSR
    // if (typeof window !== "undefined") {
    return `v1${url}`
    // }
  }

  if (internal) {
    return `/${version}/internal${url}`
  }

  return `/${version}${url}`
}

const checkHasSessionExpired = async (res: Response): Promise<boolean> => {
  if (res.status === 403) {
    const payload = await res.json()
    if (payload.type === SESSION_EXPIRED_TYPE) {
      return true
    }
  }
  return false
}

export const fetcher = async (
  url: string,
  token = getApiToken(),
  { headers, ...options }: RequestInit = {}
) => {
  if (!(headers instanceof Headers)) {
    headers = new Headers(headers)
  }

  headers = headers ?? new Headers()
  if (token) {
    headers.append("Authorization", "Bearer " + token)
  }
  if (TT_DEFAULT_JWT) {
    headers.append("x-tt-terminal-jwt", TT_DEFAULT_JWT)
  }

  headers.append("x-app-version", APP_VERSION)

  const response = await fetch(url, {
    method: "get",
    headers,
    ...options,
  })

  // Pass in response body to allow consuming body.json() inside the check
  if (!response.ok && (await checkHasSessionExpired(response.clone()))) {
    throw new SessionExpiredError(
      `${response.status} - Session has expired - ${response.url}`
    )
  }

  return response
}

type ApiReturnType<T, K> = K extends true
  ? T | null
  : K extends false
  ? T
  : never

export async function apiGet<T, K extends boolean = false>(
  path: string,
  internal = true,
  version = "v2",
  options: {
    allowNotFound?: K
    hideToken?: boolean
  } = {},
  signal?: AbortSignal
): Promise<ApiReturnType<T, K>> {
  const config = getConfiguration()
  let token = getApiToken()

  if (options.hideToken) {
    token = null
  }

  const response = await retry(
    async (bail, attempt) => {
      if (attempt > 1) {
        console.log(
          `Retrying apiGet ${addUrlPrefix(
            path,
            internal,
            version
          )} attempt ${attempt}`
        )
      }

      let res: Response
      try {
        res = await fetcher(
          config.API_URL + addUrlPrefix(path, internal, version),
          token,
          {
            method: "get",
            credentials: "include",
            signal,
          }
        )
      } catch (e: any) {
        if (e.name === "AbortError") {
          return bail(e)
        }
        if (e instanceof SessionExpiredError) {
          return bail(e)
        }
        throw e
      }

      if (!res.ok) {
        // In case eg. getStaticProps should support notFound, but still fail on 500
        if (options.allowNotFound && res.status === 404) {
          return null as ApiReturnType<T, K>
        }

        if ((res.status > 399 && res.status < 429) || res.status === 500) {
          return bail(
            new Error(`${res.status} - ${config.API_URL + addUrlPrefix(path)}`)
          )
        }

        throw new Error(
          `${res.status} - ${config.API_URL + addUrlPrefix(path)}`
        )
      }

      // No content can't be parsed
      if (res.status === 204) {
        // return null to simplify the types
        return null as any
      }

      return res
    },
    { retries: 5 }
  )

  if (!response) {
    return null as ApiReturnType<T, K>
  }

  const data = (await response.json()) as ApiReturnType<T, K>

  return data
}

async function apiPatch<T>(
  path: string,
  body: any,
  internal: boolean = true,
  version?: string
): Promise<T> {
  const token = getApiToken()
  const config = getConfiguration()

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  })

  const response = await fetcher(
    config.API_URL + addUrlPrefix(path, internal, version),
    token,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify(body),
      credentials: "include",
    }
  )

  const data = await response.json()

  if (!response.ok) {
    throw new Error(data.message)
  }

  return data
}

export async function apiPost<T>(
  path: string,
  body: any,
  internal: boolean = true,
  version?: string,
  options: {
    hideToken?: boolean
  } = {}
): Promise<T> {
  const config = getConfiguration()
  let token = getApiToken()
  if (options.hideToken) {
    token = null
  }

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  })

  const url = `${config.API_URL}${addUrlPrefix(path, internal, version)}`

  const response = await fetcher(url, token, {
    method: "post",
    body: JSON.stringify(body),
    headers,
    credentials: "include",
  })

  // No content can't be parsed
  if (response.status === 204) {
    // return null to simplify the types
    return null as any
  }

  const data = await response.json()

  if (!response.ok) {
    throw new Error(data.message)
  }

  return data
}

// Literally duplicate of apiPost with only method as difference. TODO: cleanup
export async function apiPut<T>(
  path: string,
  body: any,
  internal: boolean = true,
  version?: string,
  options: {
    hideToken?: boolean
  } = {}
): Promise<T> {
  const config = getConfiguration()
  let token = getApiToken()

  if (options.hideToken) {
    token = null
  }

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  })

  const response = await fetcher(
    config.API_URL + addUrlPrefix(path, internal, version),
    token,
    {
      method: "put",
      body: JSON.stringify(body),
      headers,
      credentials: "include",
    }
  )

  // No content can't be parsed
  if (response.status === 204) {
    // return null to simplify the types
    return null as any
  }

  const data = await response.json()

  if (!response.ok) {
    throw new Error(data.message)
  }

  return data
}

async function apiDelete<T>(
  path: string,
  internal = true,
  version?: string
): Promise<true> {
  const token = getApiToken()
  const config = getConfiguration()

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  })

  const response = await fetcher(
    config.API_URL + addUrlPrefix(path, internal, version),
    token,
    {
      method: "DELETE",
      credentials: "include",
      headers,
    }
  )
  if (!response.ok) {
    // TODO: figure out better error msg from response. Can failed response be parsed?
    throw new Error("Deleting the asset failed")
  }

  // There's no response. 204 is OK
  return true
}

export const getDateXDaysAgo = (x: number) => {
  const d = new Date()
  d.setUTCDate(d.getUTCDate() - x)
  return d.toISOString().split("T")[0]
}

export type HistoricalMetricsData = {
  [index: string]: number | string
  datetime: string
  label: string
  tooltipLabel: string
}

export type TopFetchProps = {
  limit: number
  metricName: string
  category: string
  days: number
  aggs?: `sum` | `avg`
}

export type TopProjectMetricsItem = {
  project_name: string
  value: number
  timestamp: string
}

export async function fetchTopNProjectMetrics({
  metric_id,
  market_sector_ids,
  limit,
  zoom,
  isHistorical,
}: {
  metric_id: POSSIBLE_METRICS_ENUM
  market_sector_ids?: string[]
  limit: number
  zoom: ZoomValues
  isHistorical: boolean
}) {
  const interval = getInterval(zoom)

  let url = `/metrics/top-n-projects-metrics?metric_id=${metric_id}&limit=${limit}&interval=${interval}&historical=${isHistorical}`

  // Should probably use this, but API requires the param currently.
  // if (market_sector_ids?.length && market_sector_ids[0] !== `all`) {
  //   url = url + `&market_sector_ids=${market_sector_ids.filter(id => id !== "all").join(",")}`
  // }
  url =
    url +
    `&market_sector_ids=${
      market_sector_ids?.filter((id) => id !== "all")?.join(",") ?? ""
    }`

  const result = await apiGet<{ data: TopProjectMetricsItem[] }>(url)

  return result.data
}

type ChainCompositionData = {
  [key in POSSIBLE_METRICS_ENUM]?: number
} & {
  chain: string
  project_name: string
  timestamp: string
}

export const fetchCompositionByChain = ({
  project_id,
  interval,
  metric,
  aggregates = [`chain`],
  projectJwt,
}: {
  metric: string
  aggregates?: string[]
  project_id: string
  interval: number
  projectJwt?: string
}) => {
  return apiGet<{ data: ChainCompositionData[] }>(
    `/projects/${project_id}/metrics?metric_ids=${metric}&aggregate_by=${aggregates.join(
      ","
    )}&interval=${getInterval(interval)}${
      projectJwt ? `&project_jwt=${projectJwt}` : ``
    }`,
    true
  )
}

// TODO: move to admin ui
const bloombergTickers: Record<string, string> = {
  aave: "XVVUSD KKGT Curncy",
  algorand: "XAOUSD KKGT Curncy",
  avalanche: "XAVUSD KKGT Curncy",
  "axie-infinity": "XINUSD KKGT Curncy",
  bitcoin: "XBTUSD KKGT Curncy",
  cardano: "XADUSD KKGT Curncy",
  chainlink: "XLIUSD KKGT Curncy",
  cosmos: "XATUSD KKGT Curncy",
  curve: "XTKUSD KKGT Curncy",
  dogecoin: "XDGUSD KKGT Curncy",
  ethereum: "XETUSD KKGT Curncy",
  fantom: "XFMUSD KKGT Curncy",
  filecoin: "XFIUSD KKGT Curncy",
  flow: "XFLUSD KKGT Curncy",
  internetcomputer: "XICUSD KKGT Curncy",
  litecoin: "XLCUSD KKGT Curncy",
  makerdao: "XMKUSD KKGT Curncy",
  polkadot: "XDOUSD KKGT Curncy",
  polygon: "XMAUSD KKGT Curncy",
  solana: "XSOUSD KKGT Curncy",
  stellar: "XLMUSD KKGT Curncy",
  tezos: "XXTUSD KKGT Curncy",
  tron: "XTRUSD KKGT Curncy",
  uniswap: "XUNUSD KKGT Curncy",
  zcash: "XZCUSD KKGT Curncy",
}

// Need to fill in the ticket to each project returning endpoint which ends up using useProjectPrice/useProjectsPrices
const addBBTickerToProject = <
  T extends (Partial<ProjectType> & Pick<ProjectType, "project_id">) | null,
>(
  project: T
): T => {
  if (project) {
    const ticker = bloombergTickers[project.project_id]
    if (ticker) {
      project.bb_ticker = ticker
    }
  }
  return project
}

function addAgGridColumns<
  T extends (Partial<ProjectType> & Pick<ProjectType, "project_id">) | null,
>(project: T): T {
  // necessary for typescript
  if (project) {
    project.project_logo = project.logo
    project.market_sector_id = project.market_sectors?.[0]?.id
  }

  return project
}

export const fetchProject = <T extends boolean = false>(
  id: string,
  allowNotFound?: T
) =>
  apiGet<ProjectType, T>(`/projects/${id}`, true, undefined, {
    allowNotFound,
  }).then((project) => {
    const augmentedProject = addBBTickerToProject(project)

    if (augmentedProject?.project_id === "internetcomputer") {
      ICMetricConfiguration.forEach((config) => {
        // @ts-ignore - this is temporary
        augmentedProject.metric_availability[config.metric_id] = true
      })
      Object.keys(ICMethodologies).forEach((metric) => {
        augmentedProject.metric_definitions[metric] =
          ICMethodologies[metric as keyof typeof ICMethodologies]
      })
    }

    return augmentedProject
  })

// We use same endpoint as the fetchProject but pass the jwt and we wont care about preview url param
export const fetchPreviewProject = (projectJwt: string) =>
  apiGet<ProjectType>(`/projects/preview?project_jwt=${projectJwt}`)

export const fetchProjectsAggsBq = async ({
  metric_aggregation_names,
  projectIds = "all",
  projectJwt,
}: {
  metric_aggregation_names?: string[]
  projectIds?: string[] | "all"
  projectJwt?: string
} = {}) => {
  const result = await apiGet<{ data: ProjectsMetricAggregations }>(
    `/metric-aggregations?${
      metric_aggregation_names
        ? `metric_aggregation_names=${metric_aggregation_names?.join(",")}`
        : ""
    }&project_ids=${
      Array.isArray(projectIds) ? projectIds.join(",") : projectIds
    }${projectJwt ? `&project_jwt=${projectJwt}` : ""}`
  )

  return result?.data?.map((project) =>
    addAgGridColumns(addBBTickerToProject(project))
  )
}

// These endpoints exist and work and might be handy in the future. However you should use METRIC_CONFIGURATION directly via /metrics.ts.
// It gets updated whenever "next dev" or "next build" is run
//
// export const fetchMetricConfig = async (metric_id: string) => {
//   const result = await apiGet<{ data: MetricsConfig }>(
//     `/metrics-config/${metric_id}`
//   )
//   return result.data
// }

// export const fetchMetricsConfig = async () => {
//   const result = await apiGet<{ data: MetricsConfig[] }>(
//     `/metrics-config`
//   )
//   return result.data
// }

export const fetchProjects = () => apiGet<ProjectsType>(`/projects`)

export const fetchProjectsMetadata = () =>
  apiGet<{ data: Array<ProjectsMetadataType> }>(`/projects-metadata`)

export const fetchDailyProjectMetrics = (project: string, days: number) =>
  apiGet<ProjectMetricsType>(
    `/projects/${project}/metrics?timestamp_granularity=daily&days=${days}`
  )

export const fetchProjectMetricData = (
  metric: string,
  {
    projectIds,
    interval,
    projectJwt,
  }: { projectIds: string[]; interval: string; projectJwt?: string },
  signal?: AbortSignal
) =>
  apiGet<{ data: ProjectMetricChartData[] }>(
    `/metrics/${metric}?project_ids=${projectIds}&interval=${interval}${
      projectJwt ? `&project_jwt=${projectJwt}` : ""
    }`,
    true,
    "v2",
    undefined,
    signal
  )

export interface CompetitiveChartData {
  project_name: string
  metric_id: string
  timestamp: string
  value: number
}

type FetchMarketSectorMetricsProps = {
  metric_id: string
  market_sector_id: string
  project_ids?: string
  interval: string
}

export const fetchMarketSectorMetrics = ({
  market_sector_id,
  interval,
  metric_id,
}: FetchMarketSectorMetricsProps) =>
  apiGet<{ data: CompetitiveChartData[] }>(
    `/market-sectors/${market_sector_id}/metrics?interval=${interval}&metric_id=${metric_id}&limit=20`
  )

export const fetchMarketSectorMetricsWithMeta = async ({
  market_sector_id,
  metric_id,
  interval,
}: FetchMarketSectorMetricsProps) => {
  const { data } = await fetchMarketSectorMetrics({
    market_sector_id,
    metric_id,
    interval,
  })

  return {
    data,
    id: market_sector_id,
    metricId: metric_id,
  }
}

export function getInterval(zoom: number) {
  switch (zoom) {
    case 1:
      return "24h"
    case ZOOM_ENUM.MAX:
      return "max"
    case ZOOM_ENUM.YTD:
      return "ytd"
    default:
      return `${zoom}d`
  }
}

// NOTE: This is more or less same as fetchProjectMetricData. Should be deprecated.
// TODO: fix/cleanup the intervals. We should pass interval as zoom and type as ZoomValues and use getInterval to resolve for endpoint in fetchProjectMetricData too
export const fetchMetricsForProjectIds = ({
  project_ids,
  interval,
  metric_id,
  projectJwt,
}: {
  metric_id: string
  project_ids: string
  interval: number
  projectJwt?: string
}) => {
  return apiGet<{ data: CompetitiveChartData[] }>(
    `/metrics/${metric_id}?interval=${getInterval(
      interval
    )}&project_ids=${project_ids}${
      projectJwt ? `&project_jwt=${projectJwt}` : ""
    }`
  )
}

export const fetchProjectsMomentum = () =>
  apiGet<Array<MetricsMomentum>>(`/metrics/momentum`)

export const fetchMonthlyProjectMetrics = (project: string) =>
  apiGet<ProjectMetricsType>(
    `/projects/${project}/metrics?timestamp_granularity=monthly&days=0`
  )

export const fetchChartData = async ({
  projectNames,
  project_ids,
  metric_ids,
  chartId,
  interval,
  projectJwt,
}: {
  metric_ids: POSSIBLE_METRICS_ENUM[]
  projectNames: string[]
  project_ids: string[]
  chartId: string
  interval: number
  projectJwt?: string
}) => {
  /*
   * HIGH ALARM! This endpoint for "project_names" is case sensitive.
   */
  const result = await apiGet<{ data: TimestampedChartData }>(
    `/charts/${chartId}?metric_ids=${metric_ids.join(
      ","
    )}&interval=${getInterval(interval)}&project_names=${projectNames.join(
      ","
    )}&project_ids=${project_ids.join(",")}${
      projectJwt ? `&project_jwt=${projectJwt}` : ""
    }`
  )

  return result.data
}

export const fetchMetricsWithAggregates = async () => {
  // Never should we be forced to use this endpoint without aggregates param. Metrics config is there for that.
  const { data } = await apiGet<{
    data: MetricWithAggregates[]
  }>("/metrics?include=aggregates")

  return data
}

export const loginPost = (email: string, password: string) =>
  apiPost<AuthType>(
    "/login",
    {
      email,
      password,
    },
    false,
    "v1"
  )

export const signup = (email: string, password: string, username?: string) =>
  apiPost<AuthType>(
    "/users",
    {
      email,
      password,
      username,
    },
    false,
    "v1"
  )

export const fetchUserData = () =>
  apiGet<UserDataType>("/users/me", false, "v1", { hideToken: true })

interface UserData extends Pick<UserType, "id" | "email" | "display_name"> {
  terms_of_service_accepted: boolean
  privacy_notice_accepted: boolean
}

export const updateUserData = async (
  payload: Pick<
    Partial<UserType>,
    "privacy_notice_accepted" | "terms_of_service_accepted" | "display_name"
  >
) => {
  const { user } = await apiPatch<{ user: UserData }>(
    "/users/me",
    { user: payload },
    false,
    "v1"
  )
  return user
}

export const completeUserOnboarding = async () => {
  const { user } = await apiPatch<{
    user: Pick<UserType, "subscriptionStatus" | "onboarding_completed">
  }>("/users/me/onboarding-completed", {}, false, "v1")
  return user
}

export const postUserConfig = (data: any) =>
  apiPost<any>("/users/me/config", { config: data }, false, "v1", {
    hideToken: true,
  })

export const fetchUserConfig = () =>
  apiGet<any>("/users/me/config", false, "v1", { hideToken: true })

export const logoutPost = () => apiPost("/logout", {}, false, "v1")

export const changePassword = (oldPassword: string, newPassword: string) =>
  apiPatch<null>(
    "/users/password/change",
    {
      oldPassword,
      newPassword,
    },
    false,
    "v1"
  )

export const resetPassword = (data: {
  email?: string
  password?: string
  token?: string | string[]
}) => apiPost<null>("/users/password/reset", data, false, "v1")

export const buyNow = (priceId: string) =>
  apiPost<any>("/checkout", { priceId }, false, "v1")

export const postUnsubscribe = (userId?: string | undefined) =>
  apiPost<any>(`/users/${userId}/subscription/cancel`, {}, false, "v1")

export const subscribeToNewsLetter = (email: string) =>
  apiPost<{}>("/subscribe-to-newsletter", { email }, false, "v1")

export const postPortal = () =>
  apiPost<{ url: string }>("/stripe/portal", {}, false, "v1")

export const resendEmailVerification = (email: string) =>
  apiPost<{}>("/users/resend_verification_email", { email }, false, "v1")

export const postSql = (params: SqlApiParams) =>
  apiPost<{ data: TrendingReceiverAccount[] }>("/sql", params)

export const getVestingSchedule = (projectId: string) => {
  return apiGet<{ data: Array<VestingSchedule> }>(`/token-unlocks/${projectId}`)
}

export const fetchFinancialStatement = (
  projectId: string,
  granularity: string,
  projectJwt?: string
) =>
  apiGet<{ data: FinancialStatementDataType[] }>(
    `/projects/${projectId}/financial-statement?timestamp_granularity=${granularity}${
      projectJwt ? `&project_jwt=${projectJwt}` : ""
    }`
  )

export const getChart = <T = ChartData>(chartId: string, interval?: string) => {
  let params = []
  if (interval) {
    params.push("interval=" + interval)
  }

  return apiGet<{ data: T }>(
    `/charts/${chartId}${params.length ? "?" + params.join("&") : ""}`
  )
}

export function getMartData<T>(
  martId: string,
  body: IServerSideGetRowsRequest
) {
  return apiPost<{ data: T }>(`/marts/${martId}`, body)
}

export async function getMart<T extends boolean = false>(
  slug: string,
  allowNotFound?: T
) {
  const res = await apiGet<{ data: Mart }, T>(
    `/marts/${slug}?param=slug`,
    true,
    undefined,
    { allowNotFound }
  )
  return res?.data
}

export async function getMarts() {
  const { data } = await apiGet<{ data: Mart[] }>("/marts")
  return data
}

// SSRM usage intended when needed
// export function postMartData<T>(
//   martId: string,
//   body: IServerSideGetRowsRequest
// ) {
//   return apiPost<{ data: T }>(`/marts/${martId}`, body)
// }

type MartPresetPayload = Pick<MartPreset, "config" | "mart_id" | "name">

export async function createMartPreset(payload: MartPresetPayload) {
  const { data } = await apiPost<{ data: MartPresetMeta }>(
    `/users/me/mart-presets`,
    payload,
    false,
    "v1"
  )
  return data
}

export function updateMartPreset(id: string, payload: MartPresetPayload) {
  return apiPut<void>(`/users/me/mart-presets/${id}`, payload, false, "v1")
}
export async function deleteMartPreset(id: string): Promise<void> {
  await apiDelete<{ data: MartPresetMeta }>(
    `/users/me/mart-presets/${id}`,
    false,
    "v1"
  )
}

export async function getUserPresets() {
  const { data } = await apiGet<{ data: MartPresetMeta[] }>(
    `/users/me/mart-presets`,
    false,
    "v1"
  )
  return data
}

export async function getPresets() {
  const { data } = await apiGet<{
    data: MartPreset[]
  }>(`/mart-presets`)
  return data
}

export async function getPreset(presetId: string) {
  const { data } = await apiGet<{
    data: MartPreset & { type: "user" | "tokenterminal" }
  }>(`/mart-presets/${presetId}`)
  return data
}

/**
 * Custom Charts
 */

export async function getCustomCharts() {
  const { data } = await apiGet<{ data: CustomChartType[] }>("/custom-charts")
  return data
}

export async function getCustomChart(chartId: string) {
  const { data } = await apiGet<{ data: CustomChartType }>(
    `/custom-charts/${chartId}`
  )
  return data
}

export async function updateCustomChart({
  custom_chart_id,
  ...chart
}: CustomChartType) {
  const { data } = await apiPut<{ data: CustomChartType }>(
    `/custom-charts/${custom_chart_id}`,
    chart
  )
  return data
}

export async function createCustomChart(
  chart: Omit<CustomChartType, "custom_chart_id">
) {
  const { data } = await apiPost<{ data: CustomChartType }>(
    "/custom-charts",
    chart
  )
  return data
}

export async function deleteCustomChart(
  chartId: CustomChartType["custom_chart_id"]
) {
  await apiDelete<void>(`/custom-charts/${chartId}`)
}
