import { AllHTMLAttributes, ElementType, forwardRef, ReactNode } from 'react'
import clsx from 'clsx'
// IMPORTANT TO KEEP THE IMPORT ORDER AS IS BELOW THIS COMMENT (else breaks sprinkles CSS specificity)
import { atoms, Atoms } from '@tokenterminal/ui/theme/atoms'
import { sprinkles } from '@tokenterminal/ui/theme/atoms/sprinkles.css'

export type BaseBoxProps = Omit<Atoms, 'reset'> & {
  children?: ReactNode
  className?: Parameters<typeof clsx>[0]
}

export type BoxProps = BaseBoxProps &
  Omit<
    AllHTMLAttributes<HTMLElement>,
    // Omit odd props that used as shorthand in Box spinoffs like Row
    keyof BaseBoxProps | 'start' | 'wrap'
  > & {
    as?: ElementType
    fixWhiteSpace?: boolean
  }

/**
 * Split props into atom props (display, fontSize, ...) and native html props
 */
function splitProps(
  props: Omit<BoxProps, 'as' | 'className' | 'fixWhiteSpace'>,
) {
  const atomProps: Record<string, unknown> = {}
  const nativeProps: Record<string, unknown> = {}

  for (const key in props) {
    if (key === 'bleedX' || key === 'bleedY') {
      atomProps[key] = props[key as keyof typeof props]
    } else if (
      sprinkles.properties.has(
        key as keyof Omit<
          Atoms,
          'reset' | 'background' | 'color' | 'bleedX' | 'bleedY'
        >,
      ) ||
      key === 'color' ||
      key === 'background'
    ) {
      atomProps[key] = props[key as keyof typeof props]
    } else {
      nativeProps[key] = props[key as keyof typeof props]
    }
  }

  return { atomProps, nativeProps }
}

export const Box = forwardRef<HTMLElement, BoxProps>(
  ({ as: Component = 'div', className, fixWhiteSpace, ...restProps }, ref) => {
    const { atomProps, nativeProps } = splitProps(restProps)

    const atomicClasses = atoms({
      reset:
        typeof Component === 'string'
          ? (Component as keyof JSX.IntrinsicElements)
          : 'div',
      ...atomProps,
      fontSize: fixWhiteSpace
        ? 'none'
        : (atomProps.fontSize as Atoms['fontSize']),
    })

    return (
      <Component
        ref={ref}
        className={clsx(atomicClasses, className)}
        {...nativeProps}
      />
    )
  },
)

export { Row } from './Row'
export type { RowProps } from './Row'

Box.displayName = 'Box'
