import { Text, TextProps } from './Text'
import { ReactNode } from 'react'

export function ErrorText(
  props: {
    children: ReactNode
  } & Omit<TextProps, 'color'>,
) {
  return <Text color="error" {...props} />
}
