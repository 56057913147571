import { SVGProps } from 'react'

export default function Caret(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 10 6"
      width=".625em"
      fill="none"
      color="#1A1A1A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m1 1 4 4 4-4" stroke="currentColor" />
    </svg>
  )
}
