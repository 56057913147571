function getTopLevelDomain() {
  const domain = window.location.hostname.split(".").slice(-2).join(".")
  if (domain === "localhost") {
    return "localhost"
  }
  return `.${domain}`
}

export function getCookie(cookieName: string): string | null {
  if (typeof document === `undefined`) {
    return null
  }

  const ck = document.cookie
  const cookieIndex = ck.indexOf(`${cookieName}=`)

  // cookie not found
  if (cookieIndex === -1) {
    return null
  }

  // do an extra check to make sure the cookie name is not a substring of another cookie name
  if (cookieIndex !== 0 && ck.charAt(cookieIndex - 1) !== ` `) {
    return null
  }

  const cookieValueStart = cookieIndex + cookieName.length + 1
  const cookieValueEnd = ck.indexOf(`;`, cookieValueStart)
  const cookieValue = ck.substring(
    cookieValueStart,
    cookieValueEnd === -1 ? ck.length : cookieValueEnd
  )

  return cookieValue
}

export function setCookie(
  cookieName: string,
  value: string,
  maxAge: number
): void {
  document.cookie = `${cookieName}=${value}; path=/; domain=${getTopLevelDomain()}; Max-Age=${maxAge};`
}

export function removeCookie(cookieName: string): void {
  document.cookie = `${cookieName}=; path=/; domain=${getTopLevelDomain()}; Max-Age=-1;`
}

// For typings so to avoid typos
export const parseTTAuthCookies = (
  cookies: Partial<{
    [key: string]: string
  }>
) => cookies as { session?: string; _loggedin?: string }
