import React, { SVGProps } from 'react'

export default function Link(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="currentColor" strokeMiterlimit="10">
        <path d="M3.111 4.804 1.435 6.48a2.888 2.888 0 1 0 4.084 4.085l1.676-1.676M8.888 7.196l1.676-1.676A2.887 2.887 0 1 0 6.48 1.435L4.804 3.111M3.507 8.513l4.99-4.99" />
      </g>
    </svg>
  )
}
