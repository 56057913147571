import clsx from 'clsx'
import * as resetStyles from './reset.css'
import { sprinkles } from './sprinkles.css'
import { bleedAtoms } from './bleed.css'
// Colors are special as we have light/dark theme.
import {
  highlightColorAtom,
  altHighlightColorAtom,
  primaryBackgroundColorAtom,
  primaryColorAtom,
  secondaryBackgroundColorAtom,
  secondaryColorAtom,
  errorColorAtom,
  warningColorAtom,
  invertedBackgroundColorAtom,
  invertedColorAtom,
  neutralColorAtom,
  highlightBackgroundColorAtom,
  successColorAtom,
  bodyBackgroundAtom,
  inheritBackgroundColorAtom,
  invertedHighlightBackgroundColorAtom,
  invertedHighlightColorAtom,
} from './atoms.colors.css'

type Sprinkles = Parameters<typeof sprinkles>[0]
type Bleeds = Parameters<typeof bleedAtoms>[0]

export interface Atoms extends Sprinkles {
  reset?: keyof JSX.IntrinsicElements
  bleedX?: Bleeds['marginX']
  bleedY?: Bleeds['marginY']
  background?:
    | 'primary'
    | 'secondary'
    | 'invertedHighlight'
    | 'highlight'
    | 'inverted'
    | 'body'
    | 'inherit'
  color?:
    | 'primary'
    | 'secondary'
    | 'inverted'
    | 'highlight'
    | 'invertedHighlight'
    | 'altHighlight'
    | 'success'
    | 'error'
    | 'warning'
    | 'neutral'
}

export const atoms = ({
  reset,
  bleedX,
  bleedY,
  background,
  color,
  ...rest
}: Atoms) => {
  const sprinklesClasses = sprinkles(rest)
  const bleedClasses = bleedAtoms({ marginX: bleedX, marginY: bleedY })
  let elementReset = null

  if (reset) {
    elementReset = [
      resetStyles.base,
      resetStyles.element[reset as keyof typeof resetStyles.element],
    ]
  }

  return clsx(elementReset, sprinklesClasses, bleedClasses, {
    [inheritBackgroundColorAtom]: background === 'inherit',
    [primaryBackgroundColorAtom]: background === 'primary',
    [invertedBackgroundColorAtom]: background === 'inverted',
    [secondaryBackgroundColorAtom]: background === 'secondary',
    [highlightBackgroundColorAtom]: background === 'highlight',
    [invertedHighlightBackgroundColorAtom]: background === 'invertedHighlight',
    [bodyBackgroundAtom]: background === 'body',
    [neutralColorAtom]: color === 'neutral',
    [primaryColorAtom]: color === 'primary',
    [invertedColorAtom]: color === 'inverted',
    [secondaryColorAtom]: color === 'secondary',
    [highlightColorAtom]: color === 'highlight',
    [invertedHighlightColorAtom]: color === 'invertedHighlight',
    [altHighlightColorAtom]: color === 'altHighlight',
    [successColorAtom]: color === 'success',
    [errorColorAtom]: color === 'error',
    [warningColorAtom]: color === 'warning',
  })
}
