import { useCallback, useEffect, useState } from "react"
import { createConsent, isConsentAccepted, isConsentGiven } from "./utils"
import { sendEvent } from "modules/analytics"

export function useConsent() {
  const [hasConsent, setConsent] = useState(false)
  const [isAccepted, setConsentAccepted] = useState(false)

  useEffect(() => {
    function storageHandler(event: StorageEvent) {
      // Captures all direct storage modifications, but not programmatic changes unless done in other tabs
      if (event.key === "tt_consent") {
        setConsent(isConsentGiven())
        setConsentAccepted(isConsentAccepted())
      }
    }

    addEventListener("storage", storageHandler)
    setConsent(isConsentGiven())
    setConsentAccepted(isConsentAccepted())

    return () => {
      removeEventListener("storage", storageHandler)
    }
  }, [])

  const toggleConsent = useCallback(() => {
    // Update LS and state because of how LS listener works
    setConsentAccepted((curr) => {
      const toggleState = !curr
      createConsent(toggleState, sendEvent)
      return toggleState
    })
  }, [])

  return {
    hasConsent,
    isConsentAccepted: isAccepted,
    toggleConsent,
  }
}
