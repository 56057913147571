import React, { SVGProps } from 'react'

export default function Book(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 13 12"
      fill="none"
      color="inherit"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 4H3M10 4H9M4 6H3M10 6H9M4 8H3M10 8H9"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M8 1.5h3c.83 0 1.5.67 1.5 1.5v6c0 .83-.67 1.5-1.5 1.5H8A1.5 1.5 0 0 1 6.5 9V3c0-.83.67-1.5 1.5-1.5ZM2 1.5h3c.83 0 1.5.67 1.5 1.5v6c0 .83-.67 1.5-1.5 1.5H2A1.5 1.5 0 0 1 .5 9V3c0-.83.67-1.5 1.5-1.5Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
