import { Button } from "@tokenterminal/ui/Button"
import { Heading } from "@tokenterminal/ui/Heading"
import { Stack } from "@tokenterminal/ui/Stack"
import { authModalTextStyle, authModalContent } from "./Auth.css"
import Shield from "@/icons/shield.svg"
import { Text } from "@tokenterminal/ui/Text"
import { Modal, ModalProps } from "@tokenterminal/ui/Modal"
import { theme } from "@tokenterminal/ui/theme"

type SessionExpiredModalProps = Omit<ModalProps, "children">

export const SessionExpiredModal = ({
  closeModal,
  ...modalProps
}: SessionExpiredModalProps) => (
  <Modal
    {...modalProps}
    closeModal={closeModal}
    className={authModalContent}
    closeOnEscape={false}
    closeOnOutsideClick={false}
  >
    <Stack
      className={authModalTextStyle}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      paddingY={["l", "xl"]}
      paddingX={["m", "fluidXL"]}
    >
      <Shield color={theme.colors.primary.highlightColor} width="72" />
      <Heading size="s">You have been logged out</Heading>
      <Text color="secondary">
        We have logged you out of the online service to ensure your safety.
        Please log back in.
      </Text>
      <Button marginTop="xs" onClick={closeModal}>
        Close
      </Button>
    </Stack>
  </Modal>
)
