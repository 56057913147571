// Maybe we should start using this for all hardcoded links to decrease risk of broken links. Or just external "internal" links like docs and whatnot CMS also for control/oversight
// Define in separate conts for tree-shaking and use absolute urls to support Webflow links

export const TERMS_OF_SERVICE = "https://tokenterminal.com/terms"
export const PRIVACY_POLICY = "https://tokenterminal.com/privacy-policy"
export const LOGIN_DEFAULT_REDIRECT = "/terminal"

export const AUTH_ROUTE = "/terminal/auth"
export const LOGIN_ROUTE = "/terminal/auth/login"
export const SIGNUP_ROUTE = "/terminal/auth/signup"
export const FORGOT_PASSWORD_ROUTE = "/terminal/auth/forgot-password"

export const ONBOARDING_ROUTE = "/terminal/auth/onboarding"

// README
export const DOCS_API_REFERENCE_ROUTE =
  "https://docs.tokenterminal.com/reference/api-reference"
export const DOCS_ROUTE = "https://docs.tokenterminal.com/docs/getting-started"
