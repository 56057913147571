import { POSSIBLE_METRICS_ENUM } from "data/metric-configuration"
import { createContext, useContext } from "react"
import {
  FavoriteChart,
  FavoriteProjectType,
  SubscriptionStatus,
  SubscriptionStatusType,
} from "types/Types"

export type Login = () => Promise<null | UserType>
export type UpdateUserHandler = (user: Partial<UserType>) => void
interface FavoriteTableType {
  id: string
  name: string
  columns: Array<[POSSIBLE_METRICS_ENUM, any]>
}

type AuthContextType = {
  isLoggedIn: boolean
  isLoginFinished: boolean
  setIsLoginFinished: (val: boolean) => void
  user: UserType
  token?: string
  login: Login
  logout: () => void
  updateUser: UpdateUserHandler
}

export type UserType = {
  /*
   * User may or may not have some legacy props like "trial" and "subscription", but lets not add them here
   * and instead work to get rid of them
   * -> after that we can swap subscriptionStatus to subscription
   */
  createdAt?: string
  email?: string
  id?: string
  username?: string
  display_name?: string
  company_name?: string
  privacy_notice_accepted?: boolean
  terms_of_service_accepted?: boolean
  paid: boolean
  onboarding_completed?: boolean
  subscriptionStatus: {
    type: SubscriptionStatusType
    status?: SubscriptionStatus
    created_at?: string
    start_date?: string
    end_date?: string
    cancel_at_end_date?: boolean
  }
  canManageSubscription: boolean
  apiToken?: string
  config: {
    // While initial state for user can contain favorites, logged in users dont have favorites for sure. Hence undefined must be supported for now
    favorites?: {
      charts?: Array<FavoriteChart>
      projects?: Array<FavoriteProjectType>
      markets?: Array<{ id: string; name: string }>
      metrics?: Array<any>
      // DEPRECATED
      tables?: Array<unknown | FavoriteTableType>
    }
  }
  isFree: () => boolean
  isPro: () => boolean
  isApi: () => boolean
  // is either pro or api user
  isPaid: () => boolean
}

export const initialUserState: UserType = {
  username: undefined,
  email: undefined,
  id: undefined,
  createdAt: undefined,
  paid: false,
  apiToken: undefined,
  canManageSubscription: false,
  subscriptionStatus: {
    type: "free",
  },
  isFree() {
    // fix for transpilation in local build
    const _this = this
    return _this?.subscriptionStatus?.type === "free"
  },
  isApi() {
    // fix for transpilation in local build
    const _this = this
    return _this?.subscriptionStatus?.type === "api"
  },
  isPro() {
    // fix for transpilation in local build
    const _this = this
    return _this?.subscriptionStatus?.type === "pro"
  },
  isPaid() {
    // fix for transpilation in local build
    const _this = this
    return _this.isApi() || this.isPro()
  },
  config: {
    favorites: {
      charts: [],
      markets: [],
      metrics: [],
      projects: [],
      tables: [],
    },
  },
}

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  user: initialUserState,
  isLoginFinished: false,
  setIsLoginFinished: (val: boolean) => {},
  token: undefined,
  login: () => Promise.resolve(null),
  logout: () => {},
  updateUser: () => {},
})

export const useAuth = () => useContext(AuthContext)
