import React, { SVGProps } from 'react'

export default function Plus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      color="inherit"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 12V0" stroke="currentColor" strokeMiterlimit="10" />
      <path d="M0 6H12" stroke="currentColor" strokeMiterlimit="10" />
    </svg>
  )
}
