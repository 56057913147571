import { SESSION_EXPIRED_TYPE } from "context/AuthContext"

export class SessionExpiredError extends Error {
  type: unknown

  constructor(message: string) {
    super(message)
    this.type = SESSION_EXPIRED_TYPE
  }
}
