import { forwardRef } from 'react'
import { Box, BoxProps } from './Box'

interface BaseProps
  extends Omit<BoxProps, 'start' | 'wrap' | 'end' | 'spacing' | 'reverse'> {
  wrap?: boolean
  reverse?: boolean
}

interface RowStartProps extends BaseProps {
  start?: boolean
  end?: never
}

interface RowEndProps extends BaseProps {
  end?: boolean
  start?: never
}

export type RowProps = RowStartProps | RowEndProps

export const Row = forwardRef<HTMLElement, RowProps>(
  ({ start, wrap, end, reverse, ...props }, ref) => (
    <Box
      display="flex"
      alignItems="center"
      // Let gap override both default gaps. Otherwise sprinkles holds these in higher priority
      {...(typeof props.gap !== 'undefined'
        ? null
        : {
            gridColumnGap: 'xs',
            gridRowGap: 's',
          })}
      {...(wrap && !props.flexWrap ? { flexWrap: 'wrap' } : null)}
      {...(start ? null : { justifyContent: end ? 'flexEnd' : 'spaceBetween' })}
      {...(end ? { justifyContent: 'flexEnd' } : null)}
      {...(reverse && !props.flexDirection
        ? { flexDirection: 'rowReverse' }
        : null)}
      ref={ref}
      {...props}
    />
  ),
)

Row.displayName = 'Row'
