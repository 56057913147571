import Script from "next/script"
import { useId } from "react"

/**
 * @see {https://developers.intercom.com/installing-intercom/web/attributes-objects/}
 */
export function IntercomLoader({
  intercomId,
  defaultSettings = {},
}: {
  intercomId: string
  defaultSettings?: Record<string, unknown>
}) {
  const id = useId()

  return (
    <>
      <Script
        src={`https://widget.intercom.io/widget/${id}`}
        strategy="lazyOnload"
      />
      <script
        id={`intercom-${id}`}
        dangerouslySetInnerHTML={{
          __html: `(function() {
          var hostname = window.location.hostname
          window.intercomSettings = {
            app_id: '${intercomId}',
            ...${JSON.stringify(defaultSettings)}
          }
          var i = window.Intercom;
          if (typeof i === 'function') {
            i('reattach_activator');
            i('update', window.intercomSettings);
          } else {
            i = function() {
              i.c(arguments)
            }
            i.q = [];
            i.c = function(args) {
              i.q.push(args);
            };
            window.Intercom = i;
          }
        })()`,
        }}
      />
    </>
  )
}
