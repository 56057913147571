import { ProjectMetricAggregateIntervals } from "types/ApiTypes"

export type Option<T extends any> = {
  value: T
  label: string
}

export enum ZOOM_ENUM {
  MAX = 0,
  YTD = -1,
}

export type MAX = ZOOM_ENUM.MAX
export type YTD = ZOOM_ENUM.YTD
export type ZoomValues = YTD | MAX | 1 | 7 | 30 | 90 | 180 | 365
export type ZoomOption = Option<ZoomValues> & {
  tooltip: string
  disabled?: boolean
}

export const DEFAULT_ZOOM: Array<ZoomOption> = [
  {
    label: "7D",
    value: 7,
    tooltip: `Show past week.`,
  },
  {
    label: "30D",
    value: 30,
    tooltip: `Show past month.`,
  },
  {
    label: "90D",
    value: 90,
    tooltip: `Show past quarter.`,
  },
  {
    label: "180D",
    value: 180,
    tooltip: `Show past 6 months.`,
  },
  {
    label: "YTD",
    value: ZOOM_ENUM.YTD,
    tooltip: `Show year to date.`,
  },
  {
    label: "365D",
    value: 365,
    tooltip: `Show past year.`,
  },
]

const MAX: ZoomOption = {
  label: "MAX",
  value: ZOOM_ENUM.MAX,
  tooltip: `Show since launch.`,
}

export const LATEST: ZoomOption = {
  label: "Latest",
  value: 1,
  tooltip: `Show latest.`,
}

export const ZOOM_24H: ZoomOption = {
  label: "24H",
  value: 1,
  tooltip: "Show past day.",
}

export const ZOOM_WITH_MAX = [...DEFAULT_ZOOM, MAX]

export const ZOOM_WITH_LATEST = [LATEST, ...DEFAULT_ZOOM]

export const ALL_ZOOM_LATEST = [LATEST, ...ZOOM_WITH_MAX]

export const ALL_ZOOM_24H = [ZOOM_24H, ...ZOOM_WITH_MAX]

export const getMetricAggregateInterval = (
  zoomValue: ZoomValues
): ProjectMetricAggregateIntervals => {
  if (zoomValue === ZOOM_ENUM.MAX) {
    return "all"
  }

  if (zoomValue === ZOOM_ENUM.YTD) {
    return "ytd"
  }
  return `${zoomValue}d`
}
