import { atom } from "jotai"
import { config } from "constants/config"
import { privateConfigurationAtom } from "./context"
import { Configuration } from "./types"

export const configurationAtom = atom((get) => {
  const { featureSwitches, ...config } = get(privateConfigurationAtom)

  return config
})

export function getConfiguration(): Omit<Configuration, "featureSwitches"> {
  const { featureSwitches, ...rest } = config

  return rest
}
