import React, { SVGProps } from 'react'

export default function Expand(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="currentColor" strokeMiterlimit="10">
        <path d="M.499 3.703V.473h3.518M8.01.462H11.5v3.253M11.501 8.297v3.23H7.983M3.99 11.538H.5V8.285" />
      </g>
    </svg>
  )
}
