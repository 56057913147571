import { useAuth } from "context/AuthContext"
import { useConsent } from "modules/cookieBanner/useConsent"
import { useEffect } from "react"

export function IntercomIdentityProvider() {
  const {
    isLoggedIn,
    user: {
      subscriptionStatus: { type: subscriptionStatusType },
      email,
      id: userId,
    },
  } = useAuth()
  const { hasConsent } = useConsent()

  useEffect(() => {
    // Assume login is now finished
    const user_auth_type = isLoggedIn
      ? subscriptionStatusType
      : "unauthenticated"

    const settings: Record<string, unknown> = {
      ...window.intercomSettings,
      user_auth_type,
    }

    if (hasConsent && isLoggedIn) {
      settings.user_id = userId
      settings.email = email
    }

    window.intercomSettings = settings
    if (window.Intercom) {
      window.Intercom("update", settings)
    }
  }, [isLoggedIn, subscriptionStatusType, hasConsent, email, userId])

  return null
}
