import React, { SVGProps } from 'react'

export default function Cog(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="currentColor" strokeLinejoin="round">
        <path
          clipRule="evenodd"
          d="M10.85 3.6 10.5 3c-.3-.52-.95-.7-1.46-.4a1.06 1.06 0 0 1-1.61-.9c0-.3-.1-.58-.3-.8-.2-.2-.49-.32-.78-.32h-.7a1.08 1.08 0 0 0-1.07 1.08c-.01.6-.5 1.07-1.08 1.07-.18 0-.36-.05-.51-.14a1.07 1.07 0 0 0-1.47.4l-.38.62c-.3.52-.12 1.18.4 1.48a1.09 1.09 0 0 1 0 1.88c-.52.3-.7.96-.4 1.47l.36.62a1.08 1.08 0 0 0 1.47.42 1.06 1.06 0 0 1 1.46.4c.09.15.14.33.14.51 0 .6.48 1.09 1.08 1.09h.7c.6 0 1.07-.48 1.08-1.08A1.07 1.07 0 0 1 8.5 9.3c.17 0 .35.05.5.14.52.3 1.18.12 1.47-.4l.37-.62a1.09 1.09 0 0 0-.39-1.48 1.08 1.08 0 0 1-.39-1.48c.1-.16.23-.3.4-.39.5-.3.68-.96.38-1.47v0Z"
        />
        <path d="M6 7.51c.82 0 1.48-.67 1.48-1.5 0-.82-.66-1.49-1.48-1.49s-1.48.67-1.48 1.5c0 .82.66 1.5 1.48 1.5Z" />
      </g>
    </svg>
  )
}
