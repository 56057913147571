import React, { SVGProps } from 'react'

export default function Pen(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      color="inherit"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M7.08008 2.37891L9.57008 4.87891"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M11.25 3.20094L3 11.4509H0.5V8.96094L8.75 0.710938L11.25 3.20094Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  )
}
