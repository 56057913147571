import React, { SVGProps } from 'react'

export default function Embed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m10.468 4.371 1.998 1.997-2.013 2.013M3.472 8.323 1.534 6.386l1.952-1.952M5.79 8.75 8.208 4"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
