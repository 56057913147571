import React, { SVGProps } from 'react'

export default function Save(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      color="#F7F7F7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="currentColor" strokeMiterlimit="10">
        <path d="M9.01.5H.5v11h11V2.975L9.01.5ZM8.993 8.5H3.007" />
      </g>
    </svg>
  )
}
