import { ForwardedRef, forwardRef } from 'react'
import clsx from 'clsx'
import { Button, ButtonProps } from './Button'
import { tagStyle } from './Tag.css'
import CloseIcon from '../Icons/Close'

/*
 * Tags are added and presented then with possibility of removal. No selected state.
 */
export const Tag = forwardRef(function Tag(
  {
    className,
    size = 'xsmall',
    children,
    ...rest
  }: Omit<ButtonProps, 'variant'>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Button
      ref={ref}
      className={clsx(className, tagStyle)}
      variant="ghost"
      size={size}
      {...rest}
    >
      {children}
      <CloseIcon role="presentation" aria-hidden="true" />
    </Button>
  )
})
