/** @type {Partial<import('@tokenterminal/tt-analytics-api-types/dist/api/metrics').MetricConfiguration>} */
const customMericConfiguration = {
  categories: [],
  definition: "",
  long_title: null,
  short_title: "",
  governance_forum_link: null,
  is_lower_value_better: false,
  trending: false,
  table_enabled: false,
  chart_enabled: true,
  is_gmv_metric: false,
  sources: "Internet Computer API",
  tier: "free",
  dashboard_enabled: false,
}

/** @type {Array<import('@tokenterminal/tt-analytics-api-types/dist/api/metrics').MetricConfiguration>} */
export const metricsConfiguration = [
  {
    metric_id: "total_icp_staked_on_nns",
    slug: "total-icp-staked-on-nns",
    title: "Total ICP staked on NNS",
    format: "token_ICP",
    static: true,
    serie_type: "line",
  },
  {
    metric_id: "number_of_icp_transactions",
    slug: "number-of-icp-transactions",
    title: "Number of ICP transactions",
    format: "number",
    static: false,
    serie_type: "bar",
  },
  {
    metric_id: "ckbtc_total_supply",
    slug: "ckbtc-total-supply",
    title: "ckBTC total supply",
    format: "token_ckBTC",
    static: true,
    serie_type: "bar",
  },
  {
    metric_id: "total_icp_burned",
    slug: "total-icp-burned",
    title: "Total ICP burned",
    format: "token_ICP",
    static: true,
    serie_type: "line",
  },
  {
    metric_id: "total_transaction_fees",
    slug: "total-transaction-fees",
    title: "Total transaction fees",
    format: "token_ICP",
    static: true,
    serie_type: "line",
  },
  {
    metric_id: "nns_proposals",
    slug: "nns-proposals",
    title: "NNS proposals",
    format: "number",
    static: false,
    serie_type: "bar",
  },
  {
    metric_id: "number_of_neurons",
    slug: "number-of-neurons",
    title: "Number of neurons",
    format: "number",
    static: true,
    serie_type: "line",
  },
  {
    metric_id: "total_internet_identities",
    slug: "total-internet-identities",
    title: "Total internet identities",
    format: "number",
    static: true,
    serie_type: "line",
  },
  {
    metric_id: "unique_transacting_wallets",
    slug: "unique-transacting-wallets",
    title: "Unique transacting wallets",
    format: "number",
    static: true,
    serie_type: "line",
  },
  {
    metric_id: "blocks_per_second",
    slug: "blocks-per-second",
    title: "Blocks per second",
    format: "number",
    static: true,
    serie_type: "line",
  },
  {
    metric_id: "cycles_burn_rate",
    slug: "cycles-burn-rate",
    title: "Cycles burn rate",
    format: "cycles_per_seconds",
    static: true,
    serie_type: "line",
  },
  // {
  //   metric_id: "number_of_blocks",
  //   title: "Number of Blocks",
  //   format: "number",
  //   static: false,
  // },
].map((definition) => ({ ...customMericConfiguration, ...definition }))

export const methodologies = {
  total_icp_staked_on_nns: "total amount of ICP staked in neurons.",
  number_of_icp_transactions: "number of ICP transactions.",
  ckbtc_total_supply: "total supply of ckBTC tokens.",
  total_icp_burned: "total amount of ICP burned.",
  total_transaction_fees:
    "total amount of ICP burned through transaction fees.",
  nns_proposals: "number of governance proposals.",
  number_of_neurons: "total number of staking accounts.",
  total_internet_identities: "total number of created internet identities.",
  unique_transacting_wallets:
    "number of distinct wallets making transactions on the network.",
  blocks_per_second: "average number of blocks per second.",
  cycles_burn_rate: "average number of cycles burned per second.",
}
