import React, { SVGProps } from 'react'

export default function Download(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 11.5h8M6 9.018V0M3 6.03l2.994 2.994L9.017 6"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
