import React, { SVGProps } from 'react'

export default function Info(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="currentColor" strokeMiterlimit="10">
        <path d="M6 11.499A5.499 5.499 0 1 0 6 .501a5.499 5.499 0 0 0 0 10.998ZM6 9.001V5.006M5.987 3.967v-.979" />
      </g>
    </svg>
  )
}
