import { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react'
import { Box, BaseBoxProps, BoxProps } from '../Box'
import { sizes } from '@tokenterminal/ui/theme/atoms/atoms.fontSize.css'
import {
  baseHeadlineCss,
  primaryTextCss,
  secondaryTextCss,
  successTextCss,
  terniaryTextCss,
  textLCss,
  textMCss,
  textSCss,
  textXsCss,
} from './Heading.css'

type Levels = 1 | 2 | 3 | 4
type AllowedComponents = 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span'

type HeadingBoxProps = Omit<BaseBoxProps, 'fontSize'>

export type HeadingProps<T extends AllowedComponents> = Omit<
  HTMLAttributes<HTMLHeadingElement>,
  keyof HeadingBoxProps
> &
  HeadingBoxProps & {
    level?: Levels
    as?: T
    size?: keyof typeof sizes
  }

const fontSizeLevels = {
  1: 'xl',
  2: 'l',
  3: 'm',
  4: 's',
} as const

function HeadingInner<T extends AllowedComponents>(
  {
    children,
    level = 2,
    as = 'p' as T,
    size,
    className,
    ...props
  }: HeadingProps<T>,
  ref: ForwardedRef<HTMLElement>,
) {
  if (!size) {
    size = fontSizeLevels[level]
  }

  return (
    <Box as={as} className={[sizes[size], className]} {...props} ref={ref}>
      {children}
    </Box>
  )
}

export const Heading = forwardRef(HeadingInner)

const levelComponentMap = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
} as const

export function HeadingWithCapsize({
  children,
  level,
  className,
  as,
  variant = 'primary',
  ...props
}: {
  children: ReactNode
  level: Levels
  variant?: 'primary' | 'secondary' | 'terniary' | 'success'
} & Omit<BoxProps, 'fontSize' | 'size'>) {
  if (!as) {
    as = levelComponentMap[level]
  }

  return (
    <Box
      as={as}
      className={[
        baseHeadlineCss,
        {
          [primaryTextCss]: variant === 'primary',
          [secondaryTextCss]: variant === 'secondary',
          [terniaryTextCss]: variant === 'terniary',
          [successTextCss]: variant === 'success',
          [textLCss]: level === 1,
          [textMCss]: level === 2,
          [textSCss]: level === 3,
          [textXsCss]: level === 4,
        },
        className,
      ]}
      // todo remove
      {...props}
    >
      {children}
    </Box>
  )
}
