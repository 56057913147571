import React, { SVGProps } from 'react'

export default function Close(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      viewBox="0 0 9 9"
      fill="none"
      color="inherit"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1L8 8" stroke="currentColor" strokeWidth="1" />
      <path d="M8 1L1 8" stroke="currentColor" strokeWidth="1" />
    </svg>
  )
}
