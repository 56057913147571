import { ListOfEvents } from "./types"

export function setGlobalVariable(key: string, value: string) {
  setGlobalVariables({ [key]: value })
}

export function setGlobalVariables(vars: Record<string, unknown>) {
  const dl = (window.dataLayer = window.dataLayer || [])
  dl.push(vars)
}

export function sendEvent<
  T extends keyof ListOfEvents,
  P extends ListOfEvents[T],
>(event: T, payload: P): void {
  const data = payload as { event: T } & P
  data.event = event

  const dl = (window.dataLayer = window.dataLayer || [])
  dl.push(data)
}
