import { addUrlPrefix, fetchUserData } from "api/ApiCalls"
import React, { ReactNode, useCallback, useEffect, useState } from "react"
import { useSWRConfig } from "swr"
import { UserType } from "types/Types"
import { logoutPost } from "api/ApiCalls"
import * as Sentry from "@sentry/browser"
import { getCookie, setCookie, removeCookie } from "utils/cookies"
import { sendEvent, setGlobalVariables } from "modules/analytics"
import { isConsentGiven } from "modules/cookieBanner/utils"
import { isLoggedinClass, isNotLoggedinClass } from "styles/global-state.css"
import Router from "next/router"
import { AuthContext, Login, initialUserState } from "./Auth"

export const COOKIE_SESSION = "session"
export const COOKIE_LOGGEDIN = "_loggedin"
export const COOKIE_IDTOKEN = "_idtoken"
const COOKIE_INTERNAL_USER = "_tt_internal"
const COOKIE_INTERNAL_USER_VALUE = "internal" // This is a bit silly, but might be forced by GA4/GTM
// const STORAGE_USER_DATA = "_user-data"
export const SESSION_EXPIRED_TYPE = "session-expired"

export { useAuth } from "./Auth"

// function hasCachedUserData() {
//   try {
//     if (!window.localStorage.getItem(STORAGE_USER_DATA)) {
//       throw new Error()
//     }

//     return true
//   } catch (err) {
//     return false
//   }
// }

// function saveWriteToLocalStorage(key: string, value: string) {
//   try {
//     localStorage.setItem(key, value)
//   } catch (err) {
//     // ignore
//   }
// }

function saveReadToLocalStorage(key: string) {
  try {
    return localStorage.getItem(key)
  } catch (err) {
    // ignore
  }

  return null
}

// function saveRemoveFromLocalStorage(key: string) {
//   try {
//     localStorage.removeItem(key)
//   } catch (err) {
//     // ignore
//   }
// }

const DAY_IN_SECONDS = 60 * 60 * 24

const checkInternalUser = ({ email }: UserType) => {
  // If user is our internal user, add cookie to allow separating us from realer users in Analytics
  if (email?.endsWith("@tokenterminal.xyz")) {
    if (getCookie(COOKIE_INTERNAL_USER) !== COOKIE_INTERNAL_USER_VALUE) {
      // No need to remove on logout. Maybe :thinking:
      setCookie(
        COOKIE_INTERNAL_USER,
        COOKIE_INTERNAL_USER_VALUE,
        DAY_IN_SECONDS * 30
      )
    }
  }
}

// temporary session check for backwards compatibility until _loggedin cookie is set everywhere
const getIsLoggedInCookieSet = () =>
  getCookie(COOKIE_LOGGEDIN) === "1" || Boolean(getCookie("session"))

function sendUserIdentifiedEvent(user: UserType) {
  if (user.email && user.id) {
    setGlobalVariables({
      user_id: user.id,
      tier: user.subscriptionStatus.type,
    })

    sendEvent("user_identified", {
      email: user.email,
      user_id: user.id,
      tier: user.subscriptionStatus.type,
    })

    // TOOD use router
    const currentUrl = new URL(window.location.href)
    if (
      currentUrl.searchParams.get("status") === "success" &&
      currentUrl.searchParams.get("provider") === "google"
    ) {
      sendEvent("login", {
        user_id: user.id,
        method: "google",
      })

      // remove status and provider from url to reduce tracking calls
      const currentQuery = { ...Router.query }
      delete currentQuery.status
      delete currentQuery.provider
      Router.replace({
        query: currentQuery,
      })
    }
  }
}

export const AuthProvider = ({
  hasSessionExpired,
  onLogout,
  children,
  route,
}: {
  hasSessionExpired: boolean
  onLogout?: () => void
  children: ReactNode
  route?: string
}) => {
  const [user, setUser] = useState<UserType>(initialUserState)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoginFinished, setIsLoginFinished] = useState(false)

  const updateUser = useCallback((user: Partial<UserType>) => {
    setUser((currentUser) => {
      const newUserData = {
        ...currentUser,
        ...user,
      }

      // might be worth doing in the future
      // saveWriteToLocalStorage(
      //   STORAGE_USER_DATA,
      //   JSON.stringify({
      //     id: newUserData.id,
      //     u: newUserData.username,
      //     e: newUserData.email,
      //     s: newUserData.subscription,
      //     st: newUserData.subscriptionType,
      //   })
      // )

      return newUserData
    })
  }, [])

  const { mutate } = useSWRConfig()

  useEffect(() => {
    let isCancelled = false

    async function getUserData() {
      try {
        const { user } = await fetchUserData()

        if (!isCancelled) {
          updateUser(user)
          setIsLoginFinished(true)
          setIsLoggedIn(true)
          checkInternalUser(user)
          sendUserIdentifiedEvent(user)
        }
      } catch (err) {
        const hasLoggedinCookie = getCookie(COOKIE_LOGGEDIN) === "1"
        removeCookie(COOKIE_LOGGEDIN)
        // saveRemoveFromLocalStorage(STORAGE_USER_DATA)

        // we should reload the page as _loggedin cookie is in a bad state
        if (hasLoggedinCookie) {
          window.location.reload()
        } else {
          setIsLoginFinished(true)
        }
      }
    }

    const isLoggedInWithCookie = getIsLoggedInCookieSet()

    // if (isLoggedin && hasCachedUserData()) {
    //   try {
    //     const cachedUserData = JSON.parse(
    //       localStorage.getItem(STORAGE_USER_DATA) as string
    //     )

    //     setUser({
    //       ...initialUserState,
    //       id: cachedUserData.id,
    //       username: cachedUserData.u,
    //       email: cachedUserData.e,
    //       subscription: cachedUserData.s,
    //       subscriptionType: cachedUserData.st,
    //       paid: cachedUserData.st === "free",
    //     })

    //     setIsLoggedIn(true)
    //   } catch (err) {
    //     removeCookie(COOKIE_LOGGEDIN)
    //     // saveRemoveFromLocalStorage(STORAGE_USER_DATA)
    //   }
    // }

    if (!isLoggedInWithCookie) {
      const token = saveReadToLocalStorage("token")

      if (token) {
        setCookie(COOKIE_SESSION, token, 1000 * 60 * 24 * 1)
        setCookie(COOKIE_LOGGEDIN, "1", 1000 * 60 * 24 * 1)
        setTimeout(() => {
          window.location.reload()
        }, 0)
        return
      }
    }

    getUserData()

    return () => {
      isCancelled = true
    }
  }, [updateUser])

  const login: Login = useCallback(async () => {
    try {
      const { user } = await fetchUserData()
      updateUser(user)
      setIsLoggedIn(true)
      checkInternalUser(user)
      sendEvent("login", {
        user_id: user.id,
        method: "email",
      })
      sendUserIdentifiedEvent(user)

      if (isConsentGiven()) {
        Sentry.configureScope((scope) => {
          scope.clear()
          scope.setUser({ id: user.id, email: user.email })
        })
      }

      requestAnimationFrame(() => {
        document.body.classList.add(isLoggedinClass)
        document.body.classList.remove(isNotLoggedinClass)
      })

      // TODO: use swrProjectsKey
      mutate(addUrlPrefix("/projects", true)).catch((e) => {
        console.error("Error mutating projects", e)
        Sentry.captureException(e)
      })
      return user
    } catch (err) {
      console.error("Error logging in", err)
      Sentry.captureException(err)
      return null
    } finally {
      setIsLoginFinished(true)
    }
  }, [mutate, updateUser])

  const logout = useCallback(() => {
    logoutPost()
      .then(() => {
        requestAnimationFrame(() => {
          document.body.classList.remove(isLoggedinClass)
          document.body.classList.add(isNotLoggedinClass)
        })
        onLogout?.()
      })
      .catch((e: Error) => {
        console.error("Logout failed on server side", e)
        Sentry.captureException(e)
      })

    updateUser(initialUserState)
    setIsLoggedIn(false)
    // saveRemoveFromLocalStorage(STORAGE_USER_DATA)
    Sentry.configureScope((scope) => scope.clear())
    sendEvent("logout", {})

    setGlobalVariables({
      user_id: null,
      tier: null,
    })

    // TODO: use swrProjectsKey
    mutate(addUrlPrefix("/projects", true)).catch((e) => {
      console.error(e)
    })
  }, [updateUser, mutate, onLogout])

  useEffect(() => {
    /*
     * This effect is responsible for logging out user from frontend after cookie expires.
     * Runs FE logout if user is logged in in FE but cookie is not set.
     */
    if (isLoggedIn && !getIsLoggedInCookieSet()) {
      console.warn("Cookie has expired. Logging out officially.")
      // Cookie has expired between routing
      // TODO: Instead of logging out frontend (visual/UI update here being critical), do either refresh token or Google style ask for password and so on or maybe both are same
      logout()
    }
  }, [route, isLoggedIn, logout])

  useEffect(() => {
    if (hasSessionExpired && isLoggedIn) {
      console.warn("Cookie has expired. Logging out")
      logout()
    }
  }, [logout, hasSessionExpired, isLoggedIn])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLoginFinished,
        setIsLoginFinished,
        user,
        login,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
