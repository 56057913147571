import React, {
  memo,
  ReactElement,
  ComponentType,
  Fragment,
  useState,
  useRef,
  Suspense,
} from "react"
import "../styles/global-reset.css"
import "../styles/globals.css"
import "components/layout/main.css"

import AuthProvider from "context/AuthContext"
import Head from "next/head"
import Router, { useRouter } from "next/router"

import { GTM } from "components/gtm"

import { SWRConfig } from "swr"
import { AppErrorBoundary } from "components/AppErrorBoundary"
import { AppProps as NextAppProps } from "next/app"
import { SubNavigationProps } from "types/Types"
import { APP_VERSION } from "constants/app.mjs"
import { NextComponentType, NextPageContext } from "next"
import { Provider as JotaiProvider } from "jotai"
import { FeatureSwitch } from "modules/featureswitch/FeatureSwitch"
import { useModal } from "@tokenterminal/ui/Modal"
import { SessionExpiredModal } from "components/auth/SessionExpiredModal"
import { SessionExpiredError } from "utils/errors"
import { LOGIN_ROUTE } from "constants/links"
import FkGroteskRegular from "@tokenterminal/ui/assets/fonts/FKGrotesk-Regular-subset.woff2"
import FkGroteskMedium from "@tokenterminal/ui/assets/fonts/FKGrotesk-Medium-subset.woff2"
import { IntercomIdentityProvider } from "components/intercom/IntercomIdentityProvider"
import { IntercomLoader } from "components/intercom/IntercomLoader"
import { FKGrotesk, FKGroteskMono } from "@tokenterminal/ui/Fonts"

const INTERCOM_APP_ID = "p3bihfmm"
const GTM_ID = "GTM-W3K5VQF"

function noop(font: string) {}

const LazyCookieBanner = React.lazy(() =>
  import("modules/cookieBanner/Banner").then((mod) => ({
    default: mod.CookieBanner,
  }))
)

const LazyTooltipRenderer = React.lazy(() =>
  import("modules/tooltip/TooltipRenderer").then((mod) => ({
    default: mod.TooltipRenderer,
  }))
)

type NextPageWithLayout = NextComponentType<NextPageContext, any, any> & {
  getLayout?: (page: ReactElement, args: unknown) => ReactElement
  v3?: boolean
}

interface AppProps {
  accountSubNavigation?: SubNavigationProps // TODO: remove. only used by Bloomberg atm via terminal layout
}

interface Props extends NextAppProps {
  pageProps: AppProps
}

const INITIAL_GTM_VALUES = { app: "terminal", version: APP_VERSION }

interface InnerProps extends Omit<Props, "pageProps"> {
  pageProps: AppProps
  Component: NextPageWithLayout
}

function App(props: InnerProps): ReactElement {
  const {
    Component,
    // Destroy possible nav props because they are not meant to be used without a custom layout
    pageProps: { accountSubNavigation: _extracted, ...pageProps },
  } = props

  if (Component.v3) {
    if (Component.getLayout) {
      return Component.getLayout(<Component {...pageProps} />, {})
    }

    return <Component {...pageProps} />
  }

  // https://nextjs.org/docs/basic-features/layouts
  if (Component.getLayout) {
    return Component.getLayout(<Component {...pageProps} />, pageProps)
  }

  return <Component {...pageProps} />
}

const AppHead = memo(
  function AppHead() {
    return (
      <Head>
        <link
          rel="preload"
          href={FkGroteskMedium}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={FkGroteskRegular}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link rel="preconnect" href="https://api.tokenterminal.com" />

        <title>Token Terminal | Fundamentals for crypto</title>

        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <meta
          name="facebook-domain-verification"
          content="141k7s1zlavs1vznks3nonb513g4f1"
        />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Measure and evaluate Blockchains and Dapps through traditional financial metrics. Token Terminal is a Crypto Analytics Platform with Advanced Metrics & Tools."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@tokenterminal" />
        <meta
          name="twitter:title"
          content="Token Terminal"
          key="twitter:title"
        />
        <meta
          name="twitter:description"
          key="twitter:description"
          content="Fundamentals for crypto."
        />
        <meta
          name="twitter:image"
          content="https://tokenterminal.com/thumbnail.png"
        />
        <meta
          property="og:url"
          key="og:url"
          content="https://tokenterminal.com"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
    )
  },
  () => true
)

// Fix for local development - SSRProvider doesn't seem to work correctly
// Need to check if SSRProvider
const Provider = Fragment

// No need to use react context for this. Just use global variable
global.isServerOrFirstHydration = true

function withContext(Component: ComponentType<InnerProps>) {
  noop(FKGrotesk.variable)
  noop(FKGroteskMono.variable)

  return function AppWithContext({ pageProps, ...props }: Props) {
    const renderCount = useRef(0)
    const { asPath, route } = useRouter()
    const { openModal, modalProps } = useModal()
    const [hasSessionExpired, setHasSessionExpired] = useState(false)
    // Main site doesn't support theme mode switching, so enforce default theme if necessary

    // A render means the page/route changed
    if (global.isServerOrFirstHydration && renderCount.current++ > 0) {
      global.isServerOrFirstHydration = false
    }

    return (
      <Provider>
        <JotaiProvider>
          <SWRConfig
            value={{
              provider: () => new Map(),
              revalidateOnFocus: false,
              onError: (error: any) => {
                if (
                  error instanceof SessionExpiredError &&
                  !hasSessionExpired
                ) {
                  setHasSessionExpired(true)
                  openModal()
                }
              },
              shouldRetryOnError: false,
            }}
          >
            <AuthProvider
              hasSessionExpired={hasSessionExpired}
              onLogout={() => setHasSessionExpired(false)}
              route={route}
            >
              <AppHead />
              <AppErrorBoundary>
                <Component {...props} pageProps={pageProps} />
                <SessionExpiredModal
                  {...modalProps}
                  onClosedCallback={() => {
                    Router.push(
                      `${LOGIN_ROUTE}?returnTo=${encodeURIComponent(asPath)}`
                    )
                  }}
                />
              </AppErrorBoundary>
              <FeatureSwitch feature="INTERCOM_ENABLED">
                <IntercomIdentityProvider />
              </FeatureSwitch>
            </AuthProvider>
          </SWRConfig>
          <FeatureSwitch feature="GTM_ENABLED">
            <GTM id={GTM_ID} initialValues={INITIAL_GTM_VALUES} />
          </FeatureSwitch>

          <FeatureSwitch feature="INTERCOM_ENABLED">
            <IntercomLoader
              intercomId={INTERCOM_APP_ID}
              defaultSettings={{
                hide_default_launcher: true,
                vertical_padding: 40,
              }}
            />
          </FeatureSwitch>
          <FeatureSwitch feature="COOKIE_BANNER_ENABLED">
            <Suspense fallback={null}>
              <LazyCookieBanner />
            </Suspense>
          </FeatureSwitch>
        </JotaiProvider>
        <Suspense fallback={null}>
          <LazyTooltipRenderer />
        </Suspense>
      </Provider>
    )
  }
}

export default withContext(App)
