import { getDefaultStore, useAtom } from "jotai"
import { selectAtom } from "jotai/utils"
import { Configuration, FeatureSwitches } from "modules/configuration/types"
import { privateConfigurationAtom } from "modules/configuration/context"
import type { ReactNode } from "react"

function featureSelector(config: Configuration) {
  return config.featureSwitches
}

export type Feature = keyof FeatureSwitches

export function useFeatureSwitch(feature: Feature): boolean {
  const [featureSwitches] = useAtom(
    selectAtom(privateConfigurationAtom, featureSelector)
  )

  return featureSwitches[feature]
}

export function useFeatureSwitches(): FeatureSwitches {
  const [featureSwitches] = useAtom(
    selectAtom(privateConfigurationAtom, featureSelector)
  )

  return featureSwitches
}

/**
 * Use this function in getStaticProps etc static functions, but not to be used inside React components.
 * For those cases you should use useFeatureSwitch and useFeatureSwitches
 */
export function getFeatureSwitches() {
  // if (typeof window !== "undefined") {
  //   throw new Error("This function is only available outside of React scope.")
  // }

  return getDefaultStore().get(
    selectAtom(privateConfigurationAtom, featureSelector)
  )
}

export function FeatureSwitch({
  feature,
  children,
  enabled = true,
}: {
  feature: Feature
  children: ReactNode
  enabled?: boolean
}) {
  const featureValue = useFeatureSwitch(feature)

  if (featureValue === null || featureValue !== enabled) {
    return null
  }

  return <>{children}</>
}
