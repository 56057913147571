import {
  GLOBAL_COOKIE_BANNER_CLASSNAME,
  STORAGE_CONSENT_KEY,
  CONSENT_VERSION,
} from "./constants"

export function createConsent(
  consent: boolean,
  sendEvent: (
    event: "consent_accepted" | "consent_rejected",
    payload: any
  ) => void
): void {
  try {
    localStorage.setItem(
      STORAGE_CONSENT_KEY,
      consent ? `${CONSENT_VERSION}-accepted` : `${CONSENT_VERSION}-rejected`
    )
  } catch (err) {
    // ignore
  }

  sendEvent(consent ? "consent_accepted" : "consent_rejected", {
    version: `${CONSENT_VERSION}`,
  })
}

export function isConsentGiven() {
  if (typeof localStorage === "undefined") {
    return false
  }

  const consent = window.localStorage.getItem(STORAGE_CONSENT_KEY)
  return Boolean(consent?.startsWith(`${CONSENT_VERSION}-`))
}

export function isConsentAccepted() {
  return (
    isConsentGiven() &&
    window.localStorage.getItem(STORAGE_CONSENT_KEY)!.endsWith("-accepted")
  )
}

/**
 * To reduce Largest contentfulpaint we show the consent banner as fast as possible
 * @returns
 */
export function generateCookieBannerScript(): string {
  // @ts-ignore
  function cookieBannerScript(consentKey, bannerClassName, version) {
    try {
      const consent = window.localStorage.getItem(consentKey)
      if (consent === null || !consent.startsWith(`${version}-`)) {
        document.body.classList.add(bannerClassName)
      }
    } catch (err) {}
  }

  return `(${cookieBannerScript.toString()})(${JSON.stringify(
    STORAGE_CONSENT_KEY
  )}, ${JSON.stringify(GLOBAL_COOKIE_BANNER_CLASSNAME)}, ${JSON.stringify(
    CONSENT_VERSION
  )})`
}
