import React, { SVGProps } from 'react'

export default function Trash(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="currentColor"
        d="M2 2.98v7.52a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2.98M8 2.8V1.5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v1.3M11.5 3H.5"
      />
    </svg>
  )
}
