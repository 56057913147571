import NextLink, { LinkProps } from 'next/link'
import { forwardRef, ReactNode } from 'react'
import { LinkBaseStyle, linkStyle } from './Link.css'
import { Box, BoxProps } from '../Box'

/*
 * Usage:
 * <Link href="foobar"><img id="for-example-image" />Or a button looking link or whatever</Link>
 * or
 * <Link href="foobar" styled>Our default link style</Link>
 */
interface BaseProps
  extends Pick<LinkProps, 'href' | 'prefetch' | 'shallow' | 'scroll'> {
  children: ReactNode
  styled?: boolean // renders link with default styling
  forceHyperlink?: boolean // disable next link
}

type Props = BaseProps & Omit<BoxProps, keyof BaseProps>

export type { Props as LinkProps }
export { linkStyle }

// TODO: make this better hopefully
export const isExtLink = (href?: LinkProps['href']): href is string =>
  typeof href === 'string' &&
  !!href &&
  href.trim().startsWith('http') &&
  !href.includes('tokenterminal.com')

export const Link = forwardRef<HTMLAnchorElement, Props>(function Link(
  {
    href,
    prefetch,
    className,
    styled,
    selected,
    shallow = false,
    scroll,
    forceHyperlink = false,
    ...rest
  },
  ref,
) {
  const styles = styled ? [linkStyle, className] : [LinkBaseStyle, className]

  if (isExtLink(href) || forceHyperlink) {
    return (
      <Box
        as="a"
        href={href as string}
        className={styles}
        ref={ref}
        target={forceHyperlink ? undefined : '_blank'}
        rel="noreferrer" // Allows following all now. If an issue, we need to make preferably exclude-list for unwanted ones. Idea: use re="ugc" for forum links
        {...rest}
      />
    )
  }

  return (
    <NextLink
      href={href}
      prefetch={prefetch} // Undefined considered true. If passing true, Next nags about it
      shallow={shallow}
      // automatic scroll disable upon shallow routing, simply because it makes sense. Most likely shallow used for query param changes
      scroll={typeof scroll === 'undefined' && shallow ? false : scroll}
      passHref
      legacyBehavior
    >
      <Box
        as="a"
        className={styles}
        ref={ref}
        {...(selected ? { 'aria-current': true } : null)}
        {...rest}
      />
    </NextLink>
  )
})
