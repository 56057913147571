import { forwardRef } from 'react'
import { Box, BoxProps } from './Box'

type Props = BoxProps & {
  spacing?: BoxProps['gap']
  flexDirection?: never
  children: React.ReactNode
}

export type { Props as StackProps }

export const Stack = forwardRef<HTMLElement, Props>(function Stack(
  { children, spacing = 'm', ...props },
  ref,
) {
  return (
    // max-width 100% because of flex having most likely nested flex contains causing the age old bugs with width calculations
    <Box
      display="flex"
      maxWidth="100%"
      flexDirection="column"
      gap={spacing}
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  )
})
