import { ForwardedRef, forwardRef } from 'react'
import clsx from 'clsx'
import { Button } from './Button'
import type { FilterProps } from './Filter'
import { tabStyle } from './Tab.css'

/*
 * Tab Button. Forwards Button ref so it's a button. Tabs are often links, which
 * would need to use `aria-current="true"` instead of selected.
 * In case we need to have actual tabs, we should have Tab, TabButton and Tabs maybe.
 */
export const Tab = forwardRef(function Tab(
  {
    className,
    size = 'small',
    children,
    selected = false,
    ...rest
  }: FilterProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Button
      ref={ref}
      className={clsx(className, tabStyle)}
      variant="ghost"
      size={size}
      aria-selected={selected}
      {...rest}
    >
      {children}
    </Button>
  )
})
