import { forwardRef } from 'react'
import { ButtonIcon, ButtonIconType } from './ButtonIcon'
import { Button, ButtonProps, resolveIconStyleVariant } from './Button'
import {
  iconButtonMedium,
  iconButtonGhost,
  iconButtonOutlined,
} from './IconButton.css'
import clsx from 'clsx'

export interface IconButtonProps
  extends Omit<ButtonProps, 'icon' | 'children' | 'width' | 'height'> {
  icon: ButtonIconType
  children: string // For accessibility
  width?: string
  height?: string
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(props, ref) {
    const {
      variant,
      size,
      children,
      icon,
      width = '12px',
      height = '12px',
      ...rest
    } = props

    const iconVariant = resolveIconStyleVariant({ variant, size })

    return (
      <Button ref={ref} variant={variant} size={size} {...rest}>
        <ButtonIcon
          width={width}
          height={height}
          type={icon}
          aria-label={children}
          className={clsx(
            [
              size === 'medium' && iconButtonMedium,
              iconVariant === 'outlined-medium' && iconButtonOutlined,
              iconVariant === 'ghost' && iconButtonGhost,
            ].filter(Boolean),
          )}
          standalone
        />
      </Button>
    )
  },
)
