import React from "react"
import Script from "next/script"

export const GTM = React.memo(function GTM({
  id,
  initialValues = {},
}: {
  id: string
  initialValues?: Record<string, string>
}) {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtm.js?id=${id}`}
        strategy="afterInteractive"
      />
      <Script id="gtm" strategy="afterInteractive">
        {`(function() {
          var d = window.dataLayer = window.dataLayer || [];
          d.push(${JSON.stringify(initialValues)})
          d.push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        })()`}
      </Script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
    </>
  )
})
