import { SVGProps, JSXElementConstructor } from 'react'
import Embed from '../Icons/Embed'
import Link from '../Icons/Link'
import Download from '../Icons/Download'
import Expand from '../Icons/Expand'
import Info from '../Icons/Info'
import Caret from '../Icons/Caret'
import Plus from '../Icons/Plus'
import Save from '../Icons/Save'
import Pen from '../Icons/Pen'
import Close from '../Icons/Close'
import External from '../Icons/External'
import User from '../Icons/User'
import Book from '../Icons/Book'
import Trash from '../Icons/Trash'
import Cog from '../Icons/Cog'

// TODO refactor so icons are not loaded when importing this component
export type ButtonIconType =
  | 'embed'
  | 'link'
  | 'download'
  | 'expand'
  | 'info'
  | 'caret'
  | 'close'
  | 'plus'
  | 'save'
  | 'pen'
  | 'external'
  | 'user'
  | 'book'
  | 'delete'
  | 'cog'

interface Props extends Omit<SVGProps<SVGSVGElement>, 'type'> {
  type: ButtonIconType
  standalone?: boolean
}

const getIcon = (
  type: ButtonIconType,
): JSXElementConstructor<SVGProps<SVGSVGElement>> | null => {
  switch (type) {
    case 'embed':
      return Embed
    case 'link':
      return Link
    case 'download':
      return Download
    case 'expand':
      return Expand
    case 'info':
      return Info
    case 'caret':
      return Caret
    case 'plus':
      return Plus
    case 'save':
      return Save
    case 'pen':
      return Pen
    case 'close':
      return Close
    case 'external':
      return External
    case 'user':
      return User
    case 'book':
      return Book
    case 'delete':
      return Trash
    case 'cog':
      return Cog
    default:
      return null
  }
}

export const ButtonIcon = ({ type, standalone, ...props }: Props) => {
  const Icon = getIcon(type)
  if (Icon) {
    return <Icon role={standalone ? 'img' : 'presentation'} {...props} />
  }
  return null
}
