import type { Configuration } from "modules/configuration/types"

let apiUrl = "https://api.tokenterminal.com/bloomberg/"

if (process.env.NEXT_PUBLIC_USE_LOCAL_SERVER === `true`) {
  apiUrl = "http://localhost:3001/bloomberg/"
}

if (process.env.NEXT_PUBLIC_VERCEL_ENV === `preview`) {
  apiUrl = "https://api.tokenterminal.dev/bloomberg/"
}

export const config: Configuration = {
  API_URL: apiUrl,
  API_WS_URL:
    process.env.NEXT_PUBLIC_USE_LOCAL_SERVER === `true`
      ? "http://localhost:3001/bloomberg/"
      : "https://api.tokenterminal.com/bloomberg/",
  // Allow resolving with public token for localhost. Just do NOT add the public BB variable to BB Vercel and it's fine
  API_DEFAULT_TOKEN: () =>
    process.env.BLOOMBERG_API_DEFAULT_TOKEN ??
    process.env.NEXT_PUBLIC_BLOOMBERG_API_DEFAULT_TOKEN,
  featureSwitches: {
    GTM_ENABLED: false,
    INTERCOM_ENABLED: false,
    PUBLIC_WEBSITE_ENABLED: false,
    HISTORY_NAVIGATION_ENABLED: true,
    API_DOCS_ENABLED: false,
    ACCOUNT_PAGE_ENABLED: false,
    ACCOUNT_PAGE_NAVIGATION_ENABLED: true,
    ACCOUNT_SETTINGS_ENABLED: false,
    AUTHENTICATION_MANUAL_ENABLED: false,
    METRICS_PAGE_ENABLED: true,
    MARKET_SECTORS_ENABLED: true,
    TRENDING_CONTRACTS_ENABLED: true,
    LEADERBOARDS_ENABLED: false,
    FINANCIAL_STATEMENTS_ENABLED: true,
    CUSTOM_CHARTS_ENABLED: true,
    CUSTOM_TABLES_ENABLED: true,
    DOWNLOAD_ENABLED: false,
    KEY_METRICS_ENABLED: true,
    REVENUE_SHARE_ENABLED: true,
    COMPOSITION_BY_CONTRACT_ENABLED: true,
    COMPOSITION_BY_CHAIN_ENABLED: true,
    COMPETITIVE_LANDSCAPE_ENABLED: true,
    VESTING_SCHEDULE_ENABLED: false,
    DATA_TABLE_ENABLED: true,
    CHART_MORE_INFO_ENABLED: true,
    CHART_EMBED_ENABLED: false,
    CHART_EXPAND_ENABLED: true,
    CHART_SHARE_ENABLED: false,
    SOCKET_USE_REALTIME_PRICEFEED: true,
    CHART_SOURCES_ENABLED: false,
    PROJECT_LINKS_ENABLED: false,
    PROJECT_LISTING_FORM_ENABLED: false,
    EXTERNAL_LINKS_ENABLED: false,
    MARTS_PRESETS_ENABLED: false,
    DOCS_README_ENABLED: false,
    MARTS_CUSTOM_PRESET_ENABLED: false,
    DATASET_FUNDRAISES_ENABLED: false,
    RELATED_RESOURCES_ENABLED: false,
    COOKIE_BANNER_ENABLED: false,
    OAUTH_GOOGLE_ENABLED: false,
    OAUTH_MICROSOFT_ENABLED: false,
    FOOTER_ENABLED: false,
  },
}
